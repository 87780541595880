import React from "react";
import reach from "../images/reach.jpg";
import str from "../images/str.jpg";
import clients from "../images/clients.jpg";
import Carousel from "react-bootstrap/Carousel";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import LanguageSharpIcon from "@material-ui/icons/LanguageSharp";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import "../App.scss";

const Home = () => (
  <div style={{ width: "98vw", margin: "auto" }}>
    <div className="container-fluid">
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../images/bannerpic.jpg")}
            alt="First slide"
          />
          {/* <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
        </Carousel.Item>
        {/* <Carousel.Item>
            <img
              className="d-block w-100 "
              src="https://images.wallpaperscraft.com/image/bird_silhouette_vector_134154_1920x1080.jpg"
              alt="Third slide"
            />

            <Carousel.Caption>
              <h3>Second slide label</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 carousel-fill"
              src="https://images.wallpaperscraft.com/image/bird_silhouette_vector_134154_1920x1080.jpg"
              alt="Third slide"
            />

            <Carousel.Caption>
              <h3>Third slide label</h3>
              <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
            </Carousel.Caption>
          </Carousel.Item> */}
      </Carousel>
    </div>

    <hr className="style1"></hr>

    <div className="container-fluid  d-flex justify-content-center pb-3">
      <div className="row">
        <div className="col-md-4 pb-3 shadow-sm">
          <div className="card text center ">
            <div className="card-header">
              <h5>HOW TO REACH US?</h5>
            </div>

            <div
              className="card-body home_card_data p-0 overflow-auto"
              style={{ height: "330px" }}
            >
              <img
                src={reach}
                alt="..."
                className="card-img-top"
                style={{ height: "150px" }}
              />

              <h6 className="card-text p-3 text-center">
                You can reach us through various median
              </h6>
              <ul className="px-3 pb-3">
                <li className="d-flex  align-items-center">
                  <PhoneAndroidIcon
                    style={{ color: "blue" }}
                    className="mr-2"
                  />
                  Reach us through telephones
                </li>
                <li className="d-flex align-items-center">
                  <LanguageSharpIcon
                    style={{ color: "blue" }}
                    className="mr-2"
                  />
                  Reach us through web
                </li>
                <li className="d-flex align-items-center">
                  <EmailOutlinedIcon
                    style={{ color: "blue" }}
                    className="mr-2"
                  />
                  Reach us through email
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-4 pb-3">
          <div className="card text center">
            <div className="card-header">
              <h5>OUR STRENGTH?</h5>
            </div>
            <div
              className="card-body home_card_data p-0 overflow-auto"
              style={{ height: "330px" }}
            >
              <img
                src={str}
                alt="..."
                className="card-img-top"
                style={{ height: "150px" }}
              />
              <h6 className="card-text p-3 text-center">
                Commitment is our strength
              </h6>
              <ul className="px-3 pb-3 text-center">
                <li>Reliable</li>
                <li>Dedication</li>
                <li>Affordable Price</li>
                <li>Timely Deliverables</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-4 pb-3">
          <div className="card text center">
            <div className="card-header">
              <h5>CLIENTS</h5>
            </div>
            <div
              className="card-body home_card_data p-0 overflow-data"
              style={{ height: "330px" }}
            >
              <img
                src={clients}
                alt="..."
                className="card-img-top"
                style={{ height: "150px" }}
              />
              <p className="card-text p-3 text-center">
                We have successful clients list. Submit on request
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Home;
