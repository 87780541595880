import React from "react";
const heading = { textAlign: "center", color: "darkred" };
const centrealign = { textAlign: "center" };

const About = () => (
  <div
    id="content"
    className="card col-sm-1 col-md-10 d-md-block d-sm-block"
    style={{ padding: "1rem", margin: "2rem auto" }}
  >
    <div className="card-body" style={{ padding: "0.5rem" }}>
      <div id="body" className="card-body" style={{ padding: "0.5rem" }}>
        <div id="heading" className="text-center">
          <h2
            className="text-center font-weight-bold"
            style={{ fontSize: "2rem", padding: "3px" }}
          >
            About Us
          </h2>
        </div>
        <hr className="style1"></hr>

        <p>
          L N D Engineering provider where it has a comprehensive on-site
          engineering facilities and capabilities, as well as teams of dedicated
          manpower that are available 24 hours a day, seven days a week to
          design and develop solutions specific to your requirements.
        </p>
        <p>
          We provide mechanical engineering & machining services for
          conventional & sub sea bolt tensioning, bolt torqueing, pipe cutting &
          beveling, flange facing, milling, drilling & tapping, line boring, and
          leak sealing operations to a vast range of industries ranging from
          Power Generation, Petrochemical, Offshore Oil & Gas, Marine,
          Shipbuilding & Ship Repair, Automotive and Constructions.
        </p>
        <p>
          We own a range of professional equipment which can meet the
          requirements for applications in a wide variety of fields.
        </p>
        <p style={centrealign}>
          {" "}
          We supply materials as per the specification.
        </p>
      </div>
    </div>
  </div>
);

export default About;
