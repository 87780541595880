import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import "./App.scss";
import Nav from "./components/Nav";

// PAGES
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";

const App = () => (
  <div style={{ fontFamily: "Arial" }}>
    <Nav />
    <div className="container-fluid">
      <Route exact={true} path="/" component={Home} />
      <Route exact path="/about" component={About} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/contact" component={Contact} />
    </div>
  </div>
);

// export function App() {
//   return (
//     <Router>
//       <div>
//         <ul>
//           <li>
//             <Link to="/">Home</Link>
//           </li>
//           <li>
//             <Link to="/about">About</Link>
//           </li>
//           <li>
//             <Link to="/contact-us">Contact us</Link>
//           </li>
//           <li>
//             <Link to="/services">Services</Link>
//           </li>
//         </ul>

//         <hr />

//         {/*
//           A <Switch> looks through all its children <Route>
//           elements and renders the first one whose path
//           matches the current URL. Use a <Switch> any time
//           you have multiple routes, but you want only one
//           of them to render at a time
//         */}
//         <Switch>
//           <Route exact path="/">
//             <Home />
//           </Route>
//           <Route path="/about">
//             <About />
//           </Route>
//           <Route path="/contact-us">
//             <ContactUs />
//           </Route>
//           <Route path="/services">
//             <Services />
//           </Route>
//         </Switch>
//       </div>
//     </Router>
//   );
// }

// // You can think of these components as "pages"
// // in your app.

// function Services() {
//   return (
//     <div>
//       <h2>Services</h2>
//       <div className="well">
//         <div>
//           <h3>Import & Export, Fabrication & Installation, Supplier, Trader</h3>
//         </div>
//       </div>
//       <div className="row left_right_space">
//         <h2 className="contentHeadingTwo_main">PIPE COLD CUTTING & BEVELING</h2>
//         <div className="col-md-4 col-lg-4">
//           <div className="imgHolder">
//             <img src="images/cutter.jpg" className="img-responsive" alt="image"/>
//           </div>
//         </div>
//         <div className="col-md-7 col-lg-7">
//           <div className="testimonial">
//             <h2 className="contentHeadingTwo">
//               <bold>RANGE:</bold>
//             </h2>
//             <p className="title">
//               <span>1” – 36” OD</span>
//             </p>
//             <p align="justify">
//               Our company offers high quality Pipe Beveling Machines which is
//               widely used in various industries for the beveling of pipe end. We
//               provide the best quality machine to our clients and it is known
//               for its low maintenance.
//             </p>
//           </div>
//         </div>
//       </div>
//       <div className="bottom_line">&nbsp;</div>
//       <div className="row left_right_space">
//         <div className="col-md-4 col-lg-4">
//           <div className="imgHolder">
//             <img src="images/pipes.jpg" className="img-responsive" alt="Responsive image" />
//           </div>
//         </div>
//         <div className="col-md-7 col-lg-7">
//           <div className="testimonial">
//             <h2 className="contentHeadingTwo">
//               Materials in industry standard designations:
//             </h2>
//             <p align="justify">
//               STD, 40, XH, 60, 80, 100, 120, 140, 160, XXH Special customer
//               designated nominal and minimum wall thicknesses can also be
//               furnished. Non-standard OD & ID are available.
//             </p>
//           </div>
//         </div>
//       </div>
//       <div className="bottom_line">&nbsp;</div>
//       <table
//         cellspacing="0"
//         cellpadding="0"
//         align="center"
//         className="lndtable table-responsive"
//       >
//         <tbody>
//           <tr>
//             <th width="30%">Trade Name</th>
//             <th width="15%">Grade</th>
//             <th width="15%">UNS #</th>
//             <th width="20%">Pipe</th>
//             <th width="20%">Forged / Bored Pipe *</th>
//           </tr>
//           <tr>
//             <td>
//               <strong>1 1/4 Chrome</strong>
//             </td>
//             <td>P11</td>
//             <td>K11597</td>
//             <td>A335 / SA335</td>
//             <td>A369 / SA369</td>
//           </tr>
//           <tr>
//             <td>
//               <strong>2 1/4 Chrome</strong>
//             </td>
//             <td>P22</td>
//             <td>K21590</td>
//             <td>A335 / SA335</td>
//             <td>A369 / SA369</td>
//           </tr>
//           <tr>
//             <td>
//               <strong>5 Chrome</strong>
//             </td>
//             <td>P5</td>
//             <td>K41545</td>
//             <td>A335 / SA335</td>
//             <td>A369 / SA369</td>
//           </tr>
//           <tr>
//             <td>
//               <strong>9 Chrome</strong>
//             </td>
//             <td>P9</td>
//             <td>K11597</td>
//             <td>A335 / SA335</td>
//             <td>A369 / SA369</td>
//           </tr>
//           <tr>
//             <td>
//               <strong>P91</strong>
//             </td>
//             <td>P91</td>
//             <td>K91560</td>
//             <td>A335 / SA335</td>
//             <td>A369 / SA369</td>
//           </tr>
//           <tr>
//             <td>
//               <strong>P92</strong>
//             </td>
//             <td>P92</td>
//             <td>K92460</td>
//             <td>A335 / SA335</td>
//             <td>A369 / SA369</td>
//           </tr>
//         </tbody>
//       </table>
//     </div>
//   );
// }

// function ContactUs() {
//   return (
//     <div>
//       <div className="text-center">
//         <div className="page-header innerHead" id="contactus">
//           <h2>
//             Contact Us. <small>Contact us for more.</small>
//           </h2>
//         </div>
//       </div>

//       <div className="table-responsive left_right_space">
//         <table
//           width="60%"
//           cellspacing="0"
//           cellpadding="0"
//           className="lndcontact"
//         >
//           <tbody>
//             <tr>
//               <th>
//                 <p>
//                   <b>Reg. No:&nbsp;</b>
//                 </p>
//               </th>
//               <td>
//                 <p>
//                   <strong>201418184G</strong>
//                 </p>
//               </td>
//             </tr>
//             <tr height="180">
//               <th valign="top">
//                 <p>
//                   <br />
//                   <b>Address:&nbsp;</b>
//                 </p>
//               </th>
//               <td>
//                 <p>
//                   <strong>LND Enginnering PTE. LTD.,</strong>
//                   <br />
//                   35B Hongkong Street
//                   <br />
//                   Singapore (059674)
//                   <br />
//                   <br />
//                   Phone: +65 9635 0644 / +65 6862 4271 <br />
//                   E-mail:<a href="mailto: info@lnd.sg">info@lnd.sg</a>
//                   &nbsp;/&nbsp;<a href="mailto: sales@lnd.sg">sales@lnd.sg</a>
//                 </p>
//                 <br />
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

// function About() {
//   return (
//     <div className="container">
//       <div className="innerMain">
//         <div className="text-center">
//           <div className="page-header innerHead">
//             <h2>About Us.</h2>
//           </div>
//           <div className="innerContent">
//             <p align="justify">
//               LND Engineering provider where it has a comprehensive on-site
//               engineering facilities and capabilities, as well as teams of
//               dedicated manpower that are available 24 hours a day, seven days a
//               week to design and develop solutions specific to your
//               requirements.
//             </p>
//             <p align="justify">
//               We provide mechanical engineering & machining services for
//               conventional & sub sea bolt tensioning, bolt torqueing, pipe
//               cutting & beveling, flange facing, milling, drilling & tapping,
//               line boring, and leak sealing operations to a vast range of
//               industries ranging from Power Generation, Petrochemical, Offshore
//               Oil & Gas, Marine, Shipbuilding & Ship Repair, Automotive and
//               Constructions.
//             </p>
//             <p align="justify">
//               We own a range of professional equipment which can meet the
//               requirements for applications in a wide variety of fields.
//             </p>
//             <p>We supply materials as per the specification. </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// function Home() {
//   return (
//     <div>
//       <div className="col-sm-8 col-md-6 col-lg-6">
//         <div className="bannerLeft">
//           <h2>
//             LND Engineering
//             <br />
//             Contact Us for
//           </h2>
//           <p>
//             Import &amp; Export.
//             <br />
//             Fabrication &amp; Installation.
//             <br />
//             We are Supplier &amp; Trader
//             <br />
//           </p>
//           <a className="btn btn-success">Our Services</a>
//         </div>
//       </div>
//       <div className="form">
//         <h2>
//           <img src="./images/icon-mail.png" alt="img" /> Reach Us
//         </h2>
//         <form action="#" className="form-horizontal">
//           <div className="form-group">
//             <div className="col-lg-12">
//               <input
//                 type="text"
//                 id="user-name"
//                 className="form-control"
//                 placeholder="Enter your name"
//               />
//             </div>
//           </div>

//           <div className="form-group">
//             <div className="col-lg-12">
//               <input
//                 type="text"
//                 id="user-email"
//                 className="form-control"
//                 placeholder="Enter your email"
//               />
//             </div>
//           </div>

//           <div className="form-group">
//             <div className="col-lg-12">
//               <textarea
//                 id="user-message"
//                 className="form-control"
//                 placeholder="Enter your message"
//               ></textarea>
//             </div>
//           </div>

//           <div className="form-group">
//             <div className="col-lg-10 col-lg-offset-2">
//               <button id="user-submit" className="btn btn-primary btnSend">
//                 Submit
//               </button>
//             </div>
//           </div>
//         </form>
//       </div>
//       <div className="row home_banner_bottom">
//         <div className="col-sm-9 col-md-4 col-lg-4 home_test_height">
//           <div className="panel panel-default text-center boxOne">
//             <h2>HOW TO REACH US?</h2>
//             <h3>You can reach us through various median</h3>
//             <div className="img-responsive">
//               <img
//                 src="images/reach.jpg"
//                 className="img-responsive"
//                 height=""
//                 width=""
//                 alt="reach us"
//               />
//               <br />
//               <br />
//             </div>
//             <ul className="withicon">
//               <li>
//                 <img src="images/icon-old-phone.gif" alt="" />
//                 <a>Reach Us Through Telephones </a>
//               </li>
//               <li>
//                 <img src="images/icon-earth.gif" alt="" />
//                 <a>Reach Us Through Web</a>
//               </li>
//               <li>
//                 <img src="images/icon-mobile.gif" alt="" />
//                 <a>Reach Us Through Mobile</a>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//       <div className="col-sm-9 col-md-4 col-lg-4 home_test_height">
//         <div className="panel panel-default text-center boxOne">
//           <h2>OUR STRENGTH?</h2>
//           <h3>Commitment is our strength</h3>
//           <div className="img-responsive">
//             <img
//               src="images/str.jpg"
//               className="img-responsive"
//               height=""
//               width=""
//               alt="strength"
//             />
//           </div>

//           <ul className="middleBox">
//             <li>
//               <strong>Reliable</strong>
//             </li>
//             <li>
//               <strong>Dedication</strong>
//             </li>
//             <li>
//               <strong>Affordable Price</strong>
//             </li>
//             <li>
//               <strong>Timely Deliverables</strong>
//             </li>
//           </ul>
//         </div>
//       </div>
//       <div className="col-sm-9 col-md-4 col-lg-4 home_test_height">
//         <div className="panel panel-default text-center boxTwo">
//           <h2>CLIENTS</h2>
//           <h3>We have successful clients list. Submit on request</h3>
//           <div className="img-responsive">
//             <img
//               src={"./images/clients.jpg"}
//               className="img-responsive"
//               height=""
//               width=""
//               alt="clients"
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );s
// }

export default App;
