import React, { Component } from "react";
import "./../App.scss";

export default class Services extends Component {
  render() {
    const leftalign = { textAlign: "left" };
    const rightalign = { textAlign: "right" };
    const centerAlign = { textAlign: "center" };
    return (
      <div>
        <div>
          <h2
            className="text-center font-weight-bold"
            style={{ fontSize: "2rem", padding: "5px" }}
          >
            Services
          </h2>
          <h3
            className="text-center font-weight-bold"
            style={{ fontSize: "1.5rem", margin: "3px" }}
          >
            Import & Export, Fabrication & Installation, Supplier, Trader
          </h3>
        </div>
        <div className="container py-2" style={{ margin: "18px auto" }}>
          <div className="card">
            <div className="row ">
              <div className="col-md-4 border-none">
                <img src="\images\cutter.jpg" className="w-100" />
              </div>
              <div className="col-md-8 px-3 d-flex align-items-center">
                <div className="card-block p-3">
                  <h4 className="card-title">PIPE COLD CUTTING & BEVELING</h4>
                  <div>
                    <h2 style={leftalign}>RANGE:</h2>
                    <p>1” – 36” OD</p>
                    <p>
                      Our company offers high quality Pipe Beveling Machines
                      which is widely used in various industries for the
                      beveling of pipe end. We provide the best quality machine
                      to our clients and it is known for its low maintenance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-2" style={{ margin: "18px auto" }}>
          <div className="card">
            <div className="row ">
              <div className="col-md-4">
                <img src="\images\pipes.jpg" className="w-100" />
              </div>
              <div className="col-md-8 px-3 d-flex align-items-center">
                <div className="card-block p-3">
                  <h4 className="card-title">
                    Materials in industry standard designations:
                  </h4>
                  <p className="card-text">
                    STD, 40, XH, 60, 80, 100, 120, 140, 160, XXH Special
                    customer designated nominal and minimum wall thicknesses can
                    also be furnished. Non-standard OD & ID area available.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead className="thead-dark">
              <tr>
                <th>Trader Name</th>
                <th>Grade</th>
                <th>UNS #</th>
                <th>Pipe</th>
                <th>Forged / Bored Pipe *</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1 1/4 Chrome</td>
                <td>P11</td>
                <td>K11597</td>
                <td>A335 / SA335</td>
                <td>A369 / SA369</td>
              </tr>
              <tr>
                <td>2 1/4 Chrome</td>
                <td>P22</td>
                <td>K21590</td>
                <td>A335 / SA335</td>
                <td>A369 / SA369</td>
              </tr>
              <tr>
                <td>5 Chrome</td>
                <td>P5</td>
                <td>K41545</td>
                <td>A335 / SA335</td>
                <td>A369 / SA369</td>
              </tr>
              <tr>
                <td>9 Chrome</td>
                <td>P9</td>
                <td>K11597</td>
                <td>A335 / SA335</td>
                <td>A369 / SA369</td>
              </tr>
              <tr>
                <td>P91</td>
                <td>P91</td>
                <td>K91560</td>
                <td>A335 / SA335</td>
                <td>A369 / SA369</td>
              </tr>
              <tr>
                <td>P92</td>
                <td>P92</td>
                <td>K92460</td>
                <td>A335 / SA335</td>
                <td>A369 / SA369</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
