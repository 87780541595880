import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import "./contact.scss";
let config = require("../config/config.json");
console.log(config.source);
//const config = require('config');

export function Contact(props) {
  var temp = "";
  const [inputs, setInputs] = useState({});
  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };
  const handleSubmit = (evt) => {
    evt.preventDefault();
    console.log(inputs);
    if (temp != "") {
      axios
        .post(config.source + "api/v1/send_mail", inputs)
        .then((res) => {
          alert("Message sent");
        })
        .catch(() => {
          console.log("Message not sent");
        });
    } else {
      alert("please fill the ReCAPTCHA");
    }
  };

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    // script.addEventListener("load", handleLoaded)
    document.body.appendChild(script);
  }, []);

  const expired = () => {
    //console.log(recaptchaToken, "<= your recaptcha token");
    temp = "";
  };
  const verifyCallback = (recaptchaToken) => {
    console.log(recaptchaToken, "<= your recaptcha token");
    temp = recaptchaToken;
  };
  return (
    <div className="shadow p-3 mb-5 bg-white rounded">
      <div
        id="content"
        className="card col-sm-2 col-md-10 d-md-block d-sm-block"
        style={{ margin: "auto" }}
      >
        <div className="card-body" style={{ padding: "0.5rem" }}>
          <div id="data" className="shadow-box-example z-depth-5">
            <div id="body" className="card-body" style={{ padding: "0.5rem" }}>
              <div id="heading" className="text-center">
                <h2
                  className="text-center font-weight-bold"
                  style={{ fontSize: "2rem", padding: "3px" }}
                >
                  Contact Us
                </h2>
              </div>
              <hr className="style1"></hr>
              <div id="contacts" className="d-md-flex row">
                <div className="col-sm-2 col-md d-md-flex form-group mb-2">
                  <label className="font-weight-bold p-1">Reg. No : </label>
                </div>
                <div className="col-sm-2 col-md d-md-flex mb-2">
                  <label className="d-md-flex px-2">201418184G</label>
                </div>
              </div>
              <div id="contacts1" className="d-md-flex row form-group">
                <div className="col-sm-2 col-md mb-2">
                  <label className="font-weight-bold p-1">Address :</label>
                </div>
                <div className="col-sm-1 col-md address_p">
                  <p>L N D Enginnering Pte. Ltd.</p>
                  <p>1 North Bridge Road,</p>
                  <p>#18-03 High Street Centre,</p>
                  <p>Singapore 179094.</p>
                </div>
              </div>
              <div id="contacts2" className="row">
                <div className="col-sm-2 col-md form-group mb-2">
                  <label className="font-weight-bold p-1">Contact No. </label>
                </div>
                <div className="col-sm-2 col-md address_p">
                  <p>+65 68624271 / +65 96567251</p>
                </div>
              </div>
              <div id="contacts3" className="row">
                <div className="col-sm-2 col-md form-group address_p">
                  <p className="font-weight-bold">E-mail:</p>
                </div>
                <div className="col-sm-2 col-md address_p">
                  <p>info@lnd.sg / sales@lnd.sg</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="style2"></hr>

          <div className="shadow p-3 mb-5 bg-white rounded">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <div className="form-group">
                  <label className="font-weight-bold">Email</label>
                </div>
                <input
                  type="email"
                  className="form-control col-sm-2 col-md"
                  name="email"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <div className="form-group">
                  <label className="font-weight-bold">Phone number</label>
                </div>
                <input
                  type="text"
                  className="form-control col-sm-2 col-md"
                  name="phoneNumber"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <div className="form-group">
                  <label className="font-weight-bold">Message</label>
                </div>
                <textarea
                  type="text"
                  className="form-control col-sm-2 col-md"
                  name="message"
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>

              <div className="form-group">
                {/* <div className="g-recaptcha"  data-sitekey="6LdgpvAUAAAAAGVon22NRoZsnv2qLxUldz-TrMQ1" data-callback="verifyCallback"></div> */}
                <ReCAPTCHA
                  sitekey="6LfRMfUUAAAAAGyfMyWSNCZHQj6IAmK2gNzMp6UN"
                  onChange={verifyCallback}
                  onExpired={expired}
                />
              </div>

              <div className="form-group">
                <button
                  className="btn btn-primary"
                  type="submit"
                  id="send_message"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
