import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import "./nav.scss";
class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }
  handleToggle(e) {
    e.preventDefault();
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  }
  render() {
    const { isExpanded } = this.state;

    return (
      <header
        className="header mb-0"
        style={{ borderBottom: "0.2px solid lightgray" }}
      >
        <div className="d-flex row align-items-center justify-content-around width w-50 m-0">
          <div className="col-sm-1 col-md-4">
            <Link to="/">
              <img src="./images/logo-new.png" />
            </Link>
          </div>

          <div className="title col-sm col-md ">
            L N D Engineering Pte. Ltd.
          </div>
          <img
            className="small_device"
            src={require("../images/bizsafe3.jpeg")}
            style={{
              height: "55px",
              width: "85px",
              float: "right",
            }}
          />
        </div>
        <nav className="nav">
          <i
            className="fa fa-bars self-center mt-1 mr-3"
            aria-hidden="true"
            onClick={(e) => this.handleToggle(e)}
          />

          <ul className={`collapsed ${isExpanded ? "is-expanded" : ""}`}>
            <NavLink activeClassName="active" to="/">
              <li className="nav_items">Home</li>
            </NavLink>
            <NavLink activeClassName="active" to="/about">
              <li className="nav_items">About Us</li>
            </NavLink>
            <NavLink activeClassName="active" to="/services">
              <li className="nav_items">Services</li>
            </NavLink>
            <NavLink activeClassName="active" to="/contact">
              <li className="nav_items">Contact Us</li>
            </NavLink>
          </ul>
        </nav>
        <div className="d-none d-md-block">
          <img
            src={require("../images/bizsafe3.jpeg")}
            style={{
              height: "55px",
              width: "85px",
              float: "right",
            }}
          />
        </div>
      </header>
    );
  }
}

export default Nav;
